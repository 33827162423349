<template>
  <div style="height: 100%; margin-top: -30px">
    <header
      class="c-header sticky-top r px-2 d-flex justify-content-between"
      style="background: lightgray; width: 100%"
    >
      <div class="d-flex justify-content-start">
        <a class="c-header-brand" href="#">
          <img
            src="/img/TF_demo_logo.png"
            width="160"
            height="75"
            class="d-inline-block align-top pb-2 pt-2"
            alt="TalentFind Logo"
          />
        </a>
        <ul class="c-header-nav ml-3" style="font-weight: 500">
          <li class="dropdown">
            <a class="c-header-nav-link"
              ><span class="header-nav p-1">Home</span></a
            >
          </li>
          <li class="dropdown d-none d-lg-block">
            <a class="c-header-nav-link"
              ><span class="header-nav p-1">Patients & Visitors</span
              ><span><i class="fas fa-caret-down"></i></span
            ></a>
          </li>
          <li class="dropdown d-none d-md-block">
            <a class="c-header-nav-link"
              ><span class="header-nav p-1">Health Professionals</span
              ><span><i class="fas fa-caret-down"></i></span
            ></a>
          </li>
          <li class="dropdown d-none d-md-block">
            <a class="c-header-nav-link"
              ><span class="header-nav p-1">Research & Education</span
              ><span><i class="fas fa-caret-down"></i></span
            ></a>
          </li>
          <li class="c-header-nav-item dropdown">
            <CDropdown inNav class="c-header-nav-items" add-menu-classes="pt-0">
              <template #toggler>
                <a class="c-header-nav-link"
                  ><span class="header-nav p-1">Careers</span
                  ><span><i class="fas fa-caret-down"></i></span
                ></a>
              </template>
              <CDropdownItem to="/public/job-board/12"
                ><i class="fas fa-user-md mr-3 pr-1"></i> Jobs
              </CDropdownItem>
              <CDropdownItem to="/hmgsa/register/12/organisation/76/location/7"
                ><i class="fas fa-user-plus mr-3"></i> Register
              </CDropdownItem>
              <CDropdownItem to="/hmgsa"
                ><i class="fas fa-sign-in-alt mr-3"></i> Log In
              </CDropdownItem>
            </CDropdown>
          </li>
          <li class="dropdown d-none d-md-block">
            <a class="c-header-nav-link"
              ><span class="header-nav p-1">Support us</span
              ><span><i class="fas fa-caret-down"></i></span
            ></a>
          </li>
        </ul>
      </div>
      <form
        class="form-inline mt-auto mb-auto mr-0 justify-content-end d-none d-md-block"
      >
        <input
          class="form-control mr-sm-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
      </form>
    </header>
    <div class="content position-relative">
      <div class="position-absolute">
        <h2>TalentFind General Hospital</h2>
        <h3>Demo Website</h3>
        <CButton shape="square" size="lg">Read More</CButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoTalentFind",
};
</script>

<style lang="scss" scoped>
.c-header-nav {
  .dropdown {
    cursor: pointer;
    :hover {
      color: #dc0050;
    }
  }
}
.content {
  background-image: url("/img/rsz_demo_bg.png");
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.content {
  div {
    top: 250px;
    color: white;
    width: 30%;
    left: 120px;
  }
  .btn {
    font-size: large;
    background: white;
    color: #dc0050;
  }
}
@media all and (max-width: 800px) {
  header {
    .header-nav {
      font-size: 14px !important;
    }
  }
  .content {
    height: 500px;
    background-position-x: 50%;
    .btn {
      font-size: 1rem;
    }
  }
  .content div {
    top: 250px;
    width: 50%;
    left: 25px;
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
}
</style>
