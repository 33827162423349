var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","margin-top":"-30px"}},[_c('header',{staticClass:"c-header sticky-top r px-2 d-flex justify-content-between",staticStyle:{"background":"lightgray","width":"100%"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_vm._m(0),_c('ul',{staticClass:"c-header-nav ml-3",staticStyle:{"font-weight":"500"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"c-header-nav-item dropdown"},[_c('CDropdown',{staticClass:"c-header-nav-items",attrs:{"inNav":"","add-menu-classes":"pt-0"},scopedSlots:_vm._u([{key:"toggler",fn:function(){return [_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Careers")]),_c('span',[_c('i',{staticClass:"fas fa-caret-down"})])])]},proxy:true}])},[_c('CDropdownItem',{attrs:{"to":"/public/job-board/12"}},[_c('i',{staticClass:"fas fa-user-md mr-3 pr-1"}),_vm._v(" Jobs ")]),_c('CDropdownItem',{attrs:{"to":"/hmgsa/register/12/organisation/76/location/7"}},[_c('i',{staticClass:"fas fa-user-plus mr-3"}),_vm._v(" Register ")]),_c('CDropdownItem',{attrs:{"to":"/hmgsa"}},[_c('i',{staticClass:"fas fa-sign-in-alt mr-3"}),_vm._v(" Log In ")])],1)],1),_vm._m(5)])]),_vm._m(6)]),_c('div',{staticClass:"content position-relative"},[_c('div',{staticClass:"position-absolute"},[_c('h2',[_vm._v("TalentFind General Hospital")]),_c('h3',[_vm._v("Demo Website")]),_c('CButton',{attrs:{"shape":"square","size":"lg"}},[_vm._v("Read More")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"c-header-brand",attrs:{"href":"#"}},[_c('img',{staticClass:"d-inline-block align-top pb-2 pt-2",attrs:{"src":"/img/TF_demo_logo.png","width":"160","height":"75","alt":"TalentFind Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown"},[_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Home")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown d-none d-lg-block"},[_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Patients & Visitors")]),_c('span',[_c('i',{staticClass:"fas fa-caret-down"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown d-none d-md-block"},[_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Health Professionals")]),_c('span',[_c('i',{staticClass:"fas fa-caret-down"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown d-none d-md-block"},[_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Research & Education")]),_c('span',[_c('i',{staticClass:"fas fa-caret-down"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown d-none d-md-block"},[_c('a',{staticClass:"c-header-nav-link"},[_c('span',{staticClass:"header-nav p-1"},[_vm._v("Support us")]),_c('span',[_c('i',{staticClass:"fas fa-caret-down"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-inline mt-auto mb-auto mr-0 justify-content-end d-none d-md-block"},[_c('input',{staticClass:"form-control mr-sm-2",attrs:{"type":"search","placeholder":"Search","aria-label":"Search"}})])
}]

export { render, staticRenderFns }